@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  :root {
    --background: 20, 20, 20;
    --foreground: 245, 245, 245;

    --muted: 235, 235, 235;
    --muted-foreground: 245, 245, 245;

    --popover: 235, 235, 235;
    --popover-foreground: 245, 245, 245;

    --border: 235, 235, 235;
    --input: 235, 235, 235;

    --card: 235, 235, 235;
    --card-foreground: 245, 245, 245;

    --primary: 235, 235, 235;
    --primary-foreground: 66, 32, 6;

    --secondary: 235, 235, 235;
    --secondary-foreground: 28, 25, 23;

    --accent: 235, 235, 235;
    --accent-foreground: 245, 245, 245;

    --destructive: 178, 34, 34;
    --destructive-foreground: 245, 245, 245;

    --ring: 235, 235, 235;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border font-sans;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }

  h1 {
    @apply font-bold text-3xl;
  }

  h2 {
    @apply font-bold text-xl;
  }

  ::-webkit-scrollbar {
    @apply hidden lg:flex w-[5px];
  }

  ::-webkit-scrollbar-track {
    @apply bg-background;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-md;
  }
}
